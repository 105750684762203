.modalWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1000;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  transition: background-color 0.3s ease;
  margin: 10px;
}
.success {
  background-color: #f2ffea;
  border: 1px solid #9ed47e;
  color: #4b842a;
}

.failure {
  color: #e35d5d;
  background-color: #ffe6e6;
  border: 1px solid #feafaf;
}

.modalPopup {
  position: relative;
  max-width: 500px;
  padding: 10px;
  border-radius: 5px;
}

/* Close button styles */
.modalWrapper button {
  position: absolute;
  top: 0px;
  left: 10px;
  border: none;
  background: transparent;
  font-size: 20px;
  cursor: pointer;
  color: inherit; /* Inherits color from .success or .failure */
}

.modalPopup button:hover {
  opacity: 0.8;
}
.modalWrapper {
  transition: opacity 2s; /* This makes the element fade over 2 seconds */
  opacity: 1; /* Initial full visibility */
}

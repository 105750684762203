.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--navbar-color);
  /* background-color: var(--primary-color); */
  /* background: linear-gradient(
      to right,
      var(--primary-color) 40%,
      var(--secondary-color) 50%,
      var(--primary-color) 60%
    ); */

  padding: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.logo {
  font-size: 1rem;
  /* color: #f0f3bd; */
  color: var(--navbar-text-color);
  font-weight: bold;
  margin-left: 10px;
}

.header ul {
  list-style: none;
  margin-right: 10px;
  padding: 0;
  display: flex;
  align-items: baseline;
}

.header li {
  margin-left: 3rem;
}

.header a {
  text-decoration: none;
  font-size: 1rem;
  /* color: #f0f3bd; */
  color: var(--navbar-text-color);
}

.header a:hover,
.header a:active,
.header a.active {
  color: var(--primary-color-hover);
}
.hamburgerToggle {
  border: none !important;
  color: blue;
}
.hamburgerToggle:focus {
  border: none !important;
  box-shadow: none !important;
}

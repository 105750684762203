.displayFlex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card {
  width: 18rem;
}
.cardContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr));
  gap: 16px; /* Spacing between cards */
}

/* ErrorModal.module.css */

.errorModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Faded background */
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.errorModalContent {
  background: white;
  padding: 1em;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 400px;
  text-align: center;
}

.errorHeader {
  color: red; /* Red coloring */
}

:root {
  --primary-color: #118ab2;
  --secondary-color: #b38600;
  --navbar-color: white;
  --text-color: #073b4c;
  --accent-color: #ef476f;
  /* --header-color: #118ab2; */
  --navbar-text-color: var(--primary-color);
  --primary-color-hover: #0d6684;
  --secondary-color-hover: #866502;

  --background-color: #f4f4f4;
}
body {
  background-color: var(--background-color);
}

h1,
h2,
h3,
h4 {
  color: #0e5d7a;
}
.btn-primary {
  background-color: #158ab4;
  border: none;
}
.btn-primary:hover {
  background-color: #157a95;
  border: none;
}
.btn-success {
  background-color: #178467; /* A deep teal-ish green */
  border: none;
}

.btn-success:hover {
  background-color: #10664d; /* A darker shade of the above color for hover */
  border: none;
}
a {
  color: rgb(20, 113, 253);
  text-decoration: none;
}
body {
  background-image: url("../public/background.svg"); /* Adjust the path if needed */
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed; /* This ensures the background remains fixed during scroll */
}

.btn-danger {
  background-color: #d24646; /* Muted red */
  border: none;
}
.btn-sm {
  font-weight: 500;
}

.btn-danger:hover {
  background-color: #a63838; /* A slightly darker shade for hover */
  border: none;
}

.btn-info {
  color: white;
}
.btn-info:hover {
  color: white;
}
/* --secondary-color: #d4a017; */

/* :root {
    --primary-color: #6d9dc5;
    --secondary-color: #daa49a;
    --background-color: #f2f2f2;
    --text-color: #373f51;
    --navbar-color: #6d9dc5;
    --navbar-text-color: #ffffff;
    --hovered-button-color: #567d9f;
    --error-color: #e57373;
    --success-color: #81c784;
    --info-color: #64b5f6;
    --warn-color: #ffb74d;
  } */

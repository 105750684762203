.caret {
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid rgb(20, 113, 253);
  transition: transform 0.3s ease;
  margin: 3px 8px 4px 0px;
}

.caret.expanded {
  transform: rotate(90deg);
}
.resetButton {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  color: rgb(20, 113, 253);
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumbnailList {
  display: grid;
  grid-template-columns: 1fr; /* one column that takes the full width */
  gap: 2px; /* spacing between grid items, adjust as needed */
}
/* Wider screens: Multi-column layout */
@media (min-width: 768px) {
  /* for example, targeting tablets and up */
  .thumbnailList {
    grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
    /* This will create as many columns as the width allows,
    with each column being at least 250px and filling up the remaining space equally */
  }
}

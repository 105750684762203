.checkbox {
}
.checkboxLabel {
  margin-left: 10px;
}

.form {
  display: flex;
  flex-direction: column;
}
